import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import GetStartedComponent from "../../components/GetStartedComponent";
import SpecialSwiperComponent from "../../components/SpecialSwiperComponent";
import Layout from "../../components/nigeria/layout";
import { Nigeria_MetaTags } from "../../helpers/constants";
import useSpecialScrollPosition from "../../hooks/useSpecialScrollPosition";
import { ArrowRightShortIcon, CardIcon, InterestRateIcon, NoCollateralIcon, RepeatIcon } from "../../images/nigeria/Icons";
import HeroImage from "../../images/nigeria/credits/hero.png";

const WorkingCapitals = () => {
  const { t } = useTranslation();
  const steps = [
    {
      name: t("step_one"),
      title: t("credits_page.open_step_title_1"),
      description: t("credits_page.open_step_description_1"),
      icon: (
        <StaticImage
          loading="lazy"
          key="step-1"
          className="step-image step-image-1"
          src="../../images/nigeria/credits/step-1.png"
          alt="..."
        />
      ),
    },
    {
      name: t("step_two"),
      title: t("credits_page.open_step_title_2"),
      description: t("credits_page.open_step_description_2"),
      icon: (
        <StaticImage
          loading="lazy"
          key="step-2"
          className="step-image step-image-2"
          src="../../images/nigeria/credits/step-2.png"
          alt="..."
        />
      ),
    },
    {
      name: t("step_three"),
      title: t("credits_page.open_step_title_3"),
      description: t("credits_page.open_step_description_3"),
      icon: (
        <StaticImage
          key="step-3"
          className="step-image step-image-3"
          src="../../images/nigeria/credits/step-3.png"
          alt="..."
        />
      ),
    },
    {
      name: t("step_four"),
      title: t("credits_page.open_step_title_4"),
      description: t("credits_page.open_step_description_4"),
      icon: (
        <StaticImage
          key="step-4"
          className="step-image step-image-4"
          src="../../images/nigeria/credits/step-4.png"
          alt="..."
        />
      ),
    },
  ];
  const ref = useSpecialScrollPosition(steps);

  return (
    <Layout
      pageTitle={Nigeria_MetaTags.credit.title}
      description={Nigeria_MetaTags.credit.description}
    >
      <Helmet>
        <link rel="canonical" href="https://moniepoint.com/ng/working-capital-loans" />
        <link rel="alternate" href="https://moniepoint.com/ng/working-capital-loans" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ng/working-capital-loans" hreflang="en-ng" />
      </Helmet>
      <HeroContainer src={HeroImage}>
        <div className="hero-mask"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1 className="hero-title font-grotesk">
              <Trans>credits_page.hero_title</Trans>
            </h1>
            <p className="hero-description">
              <Trans>credits_page.hero_description</Trans>
            </p>
            <a href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2" target="_blank" className="btn btn-primary">
              <Trans>apply_for_loan</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
        </div>
      </HeroContainer>
      <LargeImageContainer>
        <div className="content">
          <div className="image-wrapper">
            <StaticImage
              src="../../images/nigeria/credits/working-capitals.png"
              alt="small business loans"
            />
          </div>
        </div>
      </LargeImageContainer>
      <FeatureContainer>
        <div className="content">
          <div className="text-section">
            <h3 className="title">
              <Trans>homepage.slides.slide_4_title</Trans>
            </h3>
            <div className="image-section-sm">
              <StaticImage
                className=""
                src="../../images/nigeria/home/slide-4-sm.png"
              />
            </div>
            <div className="features-list">
              <div className="feature-item">
                <NoCollateralIcon />
                <span>
                  <Trans>credits_page.feature_1</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <InterestRateIcon />
                <span>
                  <Trans>credits_page.feature_2</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <RepeatIcon />
                <span>
                  <Trans>credits_page.feature_3</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <CardIcon />
                <span>
                  <Trans>credits_page.feature_4</Trans>
                </span>
              </div>
            </div>
            <a href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2" target="_blank" className="btn btn-primary">
              <Trans>apply_for_loan</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
        </div>
      </FeatureContainer>
      <HowToContainer ref={ref}>
        <div className="sticky-container">
          <h3 className="title">
            <Trans>credits_page.how_to_title</Trans>
          </h3>
          <SpecialSwiperComponent steps={steps} />
        </div>
      </HowToContainer>

    </Layout>
  );
};

export default WorkingCapitals;

export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 497px;
  max-height: 575px;
  position: relative;

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
    }

    .hero-description {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
    
    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 730px;
    background-size: cover;
    background-position: 70%;

    .hero-mask {
      background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0) 0%,
        rgba(29, 29, 29, 0.5) 79.14%
      );
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .hero-title {
        font-size: 32px;
        line-height: 35px;
      }
      .hero-subtitle {
        font-size: 17px;
        line-height: 27.37px;
      }
    }
  }
`;

const LargeImageContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);
  @media only screen and (max-width: 768px) {display: none}
  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 165px 150px 83px;
    @media only screen and (max-width: 1024px) {
      padding: 165px 60px 83px;
    }

    .image-wrapper {
    }
  }
`;

const FeatureContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);
  .visible-sm {
    display: unset;
  }
  .hidden-sm {
    display: none;
  }

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    padding-right: 60.5px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .image-section {
      height: auto;
      max-width: 623.55px;
      width: 50%;

      img {
        height: 100%;
      }
    }

    .text-section {
      max-width: 533px;

      .image-section-sm{
        display: none;
        margin-bottom: 48px;
        align-items: center;

        img {
          height: 427px;
        }
      }

      .title {
        font-family: "Founders Grotesk";
        font-size: 40px;
        font-weight: 500;
        line-height: 47px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 638px;
        margin-bottom: 48px;
      }

      .features-list {
        display: flex;
        flex-direction: column;
        margin-bottom: 48px;
        max-width: 444px;

        .feature-item {
          display: flex;
          gap: 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          align-items: center;

          svg > path {
            fill: rgba(11, 50, 117, 0.5);
          }
        }

        .feature-divider {
          border: 0.8px solid rgba(3, 87, 238);
          background: rgba(3, 87, 238);
          margin: 28px 0;
          position: relative;
          opacity: 0.3;

          &:after {
            position: absolute;
            bottom: -3px;
            left: 0;
            height: 6px;
            width: 6px;
            background:rgba(3, 87, 238);
            content: "";
            border-radius: 3px;
          }

          &:after {
            right: -3px;
            left: auto;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 769px) {
display: none;
  }

  @media only screen and (max-width: 768px) {
    .visible-sm {
      display: none;
    }
    .hidden-sm {
      display: unset;
    }

    .content {
      padding: 48px 20px 49px;
      flex-direction: column;
      align-items: center;

      .image-section {
      display: none;

      }

      .text-section {
        align-items: center;
        width: 100%;

        .image-section-sm{
        display: flex;
        flex-direction: column
      
      }

        .title {
          font-family: "Founders Grotesk";
          font-size: 28px;
          font-weight: 500;
          line-height: 33px;
          letter-spacing: 0em;
          text-align: center;
          max-width: 298px;
          margin: 0 auto 32px;
        }

        .features-list {
          max-width: none;
        }

        .btn.btn-primary{
          margin: 0 auto;
        }

        &.ordered-3{
          order: 3;
          margin-bottom: 0px;
        }
      }
    }
  }
`;

const HowToContainer = styled.section`
  background-color: white;
  height: ${(props) => props.height};

  .sticky-container {
    position: sticky;
    top: -1.5em;
    overflow-y: hidden;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }
    text-align: center;

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 64px;
    }

    .open-account-button {
      margin: 64px auto 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .sticky-container {
      padding: 48px 20px;
      .title {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 32px;
      }
    }
  }
`;
